
<template>
  <v-text-field
    :clearable = "!readonly"
    ref="fecha"
    :autofocus="autofocus"
    :label="label"
    v-model="valor"
    variant="outlined"
    density="compact"
    autocomplete="false"
    type="datetime-local"
    :loading="validating"
    :readonly="readonly"
    :disabled="validating"
    prepend-inner-icon="mdi-clock-check-outline"
    :placeholder="textoAlternativo"
    :hint="MensajeAyuda"
    :min="minFec"
    :max="maxFec"
    @change="SetValueChange"
    :rules="[
      this.requerido && !this.valor ? 'Es requerido' : null,
      this.minFec && new Date(this.minFec) > new Date(this.valor)
        ? 'Debe superar el ' + this.minFecTXT + '!'
        : null,
      this.maxFec && new Date(this.maxFec) < new Date(this.valor)
        ? 'No debe superar el ' + this.maxFecTXT + '!'
        : null,
    ]"
  >
  </v-text-field>
</template>
  
  <script>
import { formatoFechaHoraTXT,formatoFechaHoraVue } from "@/helpers/helpers.js";

export default {
  data: () => ({
    readonly: false,
    valor: null,
    valorTXT: null,
    label: "Fecha/Hora",
    validating: null,
    autofocus: false,
    minFec: null,
    minFecTXT: null,
    maxFec: null,
    maxFecTXT: null,
    requerido: null,
    textoAlternativo: "dd-mm-yyyy",
    MensajeAyuda: "Fecha/Hora",
  }),
  watch: {
    valorSet: function (val) {
      this.valor = formatoFechaHoraVue(val);
      this.valorTXT = formatoFechaHoraVue(val);
    },
    labelSet: function (val) {
      this.label = val;
    },
    validatingSet: function (val) {
      this.validating = val;
    },
    minFecSet: function (val) {
      this.minFec = formatoFechaHoraVue(val);
      this.minFecTXT = formatoFechaHoraVue(val);
    },
    maxFecSet: function (val) {
      this.maxFec = formatoFechaHoraVue(val);
      this.maxFecTXT = formatoFechaHoraVue(val);
    },
    textoAlternativoSet: function (val) {
      this.textoAlternativo = val;
    },
    MensajeAyudaSet: function (val) {
      this.textoAlternativo = val;
    },
    autofocusSet: function (val) {
      if (val) {
        this.$refs.fecha.focus();
      }
    },
    valor: function (val) {
      this.$emit("SetValor", val);
    },
    requeridoSet: function (val) {
      this.requerido = val;
    },
    readonlySet: function (val) {
      this.readonly = val;
      if(this.readonly){
        this.clearable =false;
      }else{
        this.clearable =false;
      }
    },
  },
  props: {
    valorSet: String,
    labelSet: String,
    validatingSet: Boolean,
    autofocusSet: Boolean,
    minFecSet: Date,
    maxFecSet: Date,
    textoAlternativoSet: String,
    MensajeAyudaSet: String,
    requeridoSet: Boolean,
    readonlySet: Boolean,
  },
  mounted: async function () {
    if (this.labelSet) {
      this.label = this.labelSet;
    }
    if (this.valorSet) {
      this.valor = formatoFechaHoraVue(this.valorSet);
      this.valorTXT = formatoFechaHoraVue(this.valorSet);
    }
    if (this.validatingSet) {
      this.validating = this.validatingSet;
    }
    if (this.autofocusSet) {
      this.autofocus = this.autofocusSet;
    }
    if (this.minFecSet) {
      this.minFec = formatoFechaHoraVue(this.minFecSet);
      this.minFecTXT = formatoFechaHoraVue(this.minFecSet);
    }
    if (this.maxFecSet) {
      this.maxFec = formatoFechaHoraVue(val);
      this.maxFecTXT = formatoFechaHoraVue(val);
    }
    if (this.textoAlternativoSet) {
      this.textoAlternativo = this.textoAlternativoSet;
    }
    if (this.MensajeAyudaSet) {
      this.MensajeAyuda = this.MensajeAyudaSet;
    }
    if (this.requeridoSet) {
      this.requerido = this.requeridoSet;
    }
    if (this.readonlySet) {
      this.readonly = this.readonlySet;
      if(this.readonly){
        this.clearable =false;
      }else{
        this.clearable =true;
      }
    }
  },
  methods: {
    SetValueChange: function(){
      this.$emit("SetValueChange",this.valor);
    }
  }
};
</script>
  
  <style>
</style>