<template>
    <v-container :class="isMobile ? 'px-0 py-0' : ''">
    
    <v-form ref="form_ingreso" @submit.prevent="guardarPasajeros"
    v-model="validForm">
    <div class="text-h6">Pasajeros</div>
    <v-row dense class="text-caption" v-if="rutaLocal">
      <v-col cols="12">
        Ruta con {{ ruta.legs.length }} destino{{
          ruta.legs.length > 0 ? "s" : ""
        }}:
      </v-col>
      <v-col cols="12" md="6" lg="4" v-for="(destino, index) in rutaLocal.legs" :key="index">
        <textos
          v-model="pasajeros[index]"
          :labelSet="'PAX a ' + destino.destino.direccion"
          :textoAlternativoSet="'Destino ' + destino.destino.direccion"
          :requeridoSet="true"
          :maxLengthSet="100"
          :minLengthSet="5"
          :validatingSet="validating"
        ></textos>
      </v-col>
    </v-row>
      <v-row dense class="text-caption" v-if="rutaLocal">
      <v-col cols="6" md="3" lg="2" align="left"> kilometros:</v-col>
      <v-col cols="6" md="3" lg="2" align="right" class="pr-md-12">
        {{ (ruta.kilometros + "").replace(".", ",") }}.
      </v-col>
      <v-col cols="6" md="3" lg="2" align="left"> Minutos: </v-col>
      <v-col cols="6" md="3" lg="2" align="right" class="pr-md-12">
        {{ (ruta.tiempo_trafico + "").replace(".", ",") }}.
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
      <v-col cols="6" md="3" lg="2" align="left"> Bajada de bandera: </v-col>
      <v-col cols="6" md="3" lg="2" align="right" class="pr-md-12">
        {{ formatoMonedaChile(ruta.bajada_bandera) }}.
      </v-col>
      <v-col cols="6" md="3" lg="2" align="left"> Valor Kilometros: </v-col>
      <v-col cols="6" md="3" lg="2" align="right" class="pr-md-12">
        {{ formatoMonedaChile(ruta.kilometros_precio) }}.
      </v-col>
      <v-col cols="6" md="3" lg="2" align="left"> Valor tráfico: </v-col>
      <v-col cols="6" md="3" lg="2" align="right" class="pr-md-12">
        {{ formatoMonedaChile(ruta.tiempo_demora_precio) }}.
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
      <v-col cols="6" md="3" lg="2" align="left"> Total: </v-col>
      <v-col cols="6" md="3" lg="2" align="right" class="pr-md-12">
        {{ formatoMonedaChile(ruta.total) }}.
      </v-col>
    </v-row>
    <v-row dense class="text-caption pt-3">
      <!--
      Esto sólo se usa cuando es mobile
      -->
      <v-col :cols="12" v-if="rutaLocal && isMobile">
        <buttonSubmitform
          labelSet="Ir a valores del viaje"
        append-icon="mdi-chevron-right"
        />
      </v-col>
      <v-col :cols="isMobile?12:6">
        <buttonCancelform
          labelSet="Ver Destinos"
          @accion="volverAlternativasCarrera"
          preIcon="mdi-chevron-left"
        />
      </v-col>
      <v-col cols="6" v-if="rutaLocal && !isMobile">
        <buttonSubmitform
          labelSet="Ir a valores del viaje"
        append-icon="mdi-chevron-right"
        />
      </v-col>
    </v-row>
    </v-form>
  </v-container>
  <mensajeUsuario ref="mensajeUsuario" @mensajeUsuario="mensajeUsuario"/>
</template>

<script>
import mensajeUsuario from "@/components/corss/mensajeUsuario.vue";
import textos from "@/components/form/textos.vue";
import { formatoMonedaChile, isMobile } from "@/helpers/helpers";
import buttonCancelform from "@/components/form/buttonCancelform.vue";
import buttonSubmitform from "@/components/form/buttonSubmitform.vue";
export default {
  name: "registraPasajerosCarrera",

  data: () => ({
    rutaLocal: null,
    validForm: null,
    validating: null,
    pasajeros: [],
    formatoMonedaChile: formatoMonedaChile,
    isMobile: isMobile(),
  }),
  props: {
    ruta: Object,
    pasajerosSet: Object,
  },
  components: {
    textos,
    buttonCancelform,
    buttonSubmitform,
    mensajeUsuario
  },
  watch: {
    ruta: function (ruta) {
        this.rutaLocal = ruta;
    },
    pasajerosSet: function(val){
      this.pasajeros = val;
    }
  },
  mounted: function () {
    if(this.ruta){
        this.rutaLocal = this.ruta;
    }
    if(this.pasajerosSet){
      this.pasajeros = this.pasajerosSet;
    }
  },
  methods: {
    volverAlternativasCarrera: function () {
      this.$emit("volverAlternativaCarrera");
    },
    guardarPasajeros: async function(){
      const { valid } = await this.$refs.form_ingreso.validate();
      if(valid){
        this.$emit("setearPasajeros",this.pasajeros);
      }else{
        this.$refs.mensajeUsuario.mostrar("Debe ingresar los datos requeridos","Advertencia");
      }
    },
  },
};
</script>

<style>
</style>