<template>
  <v-container :class="isMobile?'mx-0 px-0 my-1 py-1':''">
    <v-card
      class="mx-auto"
      elevation-6
      :style="'background-color: ' + paletColor.card.backgroundgral + ';'"
      max-width="90%"
    >
      <v-card-title
        style="height: 80px"
        :style="'color: ' + paletColor.buttons.cancel.text + ';'"
      >
      <v-row>
        <v-col>Nuevo Viaje</v-col>
        <v-col align="right"><v-btn :style="'background-color: ' + paletColor.card.backgroundgral + ';'" density="compact"  icon="mdi-close-circle-outline" @click="volverMenu"></v-btn></v-col>
      </v-row>
        
      </v-card-title>
      <v-card-text :style="'background-color: ' + paletColor.colorNeutro + ';'">
        <div v-if="windowItem == 1">
          <buscadorDirecciones @setearRutas="setearRutas" :calculaCarrera="true" 
          @setearServicioConvenio="setearServicioConvenio"/>
        </div>
        <div v-if="windowItem == 2">
          <alternativaRutas :data="data" @usarRuta="usarRuta" />
          <buttonCancelform labelSet="Destinos Viaje" @accion="volverBuscaCarrera"  preIcon="mdi-chevron-left"/>
        </div>
        <div v-if="windowItem == 3">
          <registraPasajerosCarrera
            :ruta="ruta"
            @volverAlternativaCarrera="volverAlternativaCarrera"
            @setearPasajeros="setearPasajeros"
          />
        </div>
        <div v-if="windowItem == 4">
          <registraDatosCarrera
            :data="data"
            :ruta="ruta"
            :pasajeros="pasajeros"
            :empresa_cod="empresa_cod"
            :servicio_cod="servicio_cod"
            :convenio_cod="convenio_cod"
            @volverRegistroPasajetros="volverRegistroPasajetros"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-container>
  <mensajeUsuario ref="mensajeUsuario" />
  <mensajeUsuario ref="mensajeUsuarioSalir" @cerrar="SalirSistema" />
</template>
  
  
  <script>
import buttonCancelform from "../form/buttonCancelform.vue";
import buttonSubmitform from "../form/buttonSubmitform.vue";
import txt_fechacarrera from "../form/fechaHora.vue";
import buscadorDirecciones from "./componentesCarrera/buscadorDirecciones.vue";
import alternativaRutas from "./componentesCarrera/alternativaRutas.vue";
import registraPasajerosCarrera from "./componentesCarrera/registraPasajerosCarrera.vue";
import registraDatosCarrera from "./componentesCarrera/registraDatosCarrera.vue";
import mensajeUsuario from "../corss/mensajeUsuario.vue";
import {
  enviarJsonPOST,
  paletColor,
  formatoFechaHoraTXT,
  formatoFechaHoraVue,
  isMobile,
} from "@/helpers/helpers.js";

export default {
  name: "start",

  data: () => ({
    paletColor: paletColor,
    windowItem: 1,
    empresa_cod: null,
    servicio_cod: null,
    convenio_cod: null,
    data: null,
    ruta: null,
    pasajeros: null,
    usuario: JSON.parse(sessionStorage.getItem("usuarioDriverIn")),
    apiUrl: process.env.VUE_APP_API_URL,
    debugMode: process.env.VUE_APP_DEBUG_MODE === "true", // Convierte a booleano si es necesario
    isMobile: isMobile(),
  }),
  components: {
    buttonCancelform,
    buttonSubmitform,
    txt_fechacarrera,
    buscadorDirecciones,
    alternativaRutas,
    registraPasajerosCarrera,
    registraDatosCarrera,
    mensajeUsuario,
  },
  mounted: function () {
  },
  methods: {
    setearRutas: async function (data, empresa_cod, servicio_cod, convenio_cod) {
      if (data) {
        this.data = data;
        this.empresa_cod = empresa_cod;
        this.setearServicioConvenio(servicio_cod,convenio_cod)
        this.windowItem = 2;
      } else {
        this.$refs.mensajeUsuario.mostrar(
          "Debe tener al menos un origen y un destino."
        );
      }
    },
    setearServicioConvenio: async function(servicio_cod, convenio_cod){
        this.servicio_cod = servicio_cod;
        this.convenio_cod = convenio_cod;
    },
    usarRuta: async function (ruta) {
      if (ruta) {
        this.ruta = ruta;
        this.windowItem = 4;
      } else {
        this.$refs.mensajeUsuario.mostrar("Debe tener una ruta seleccionada.");
      }
    },
    setearPasajeros: function (pasajeros) {
      if (pasajeros) {
        this.pasajeros = pasajeros;
        this.windowItem = 4;
      } else {
        this.$refs.mensajeUsuario.mostrar(
          "Debe ingresar los pásajeros de la ruta."
        );
      }
    },
    volverBuscaCarrera: function () {
      this.windowItem = 1;
    },
    volverAlternativaCarrera: function () {
      this.windowItem = 2;
    },
    volverRegistroPasajetros: function () {
      this.windowItem = 3;
    },
    volverMenu: function(){
      this.$router.go(-1);
    },
    SalirSistema: function(){
      this.$router.replace("/");
    }
  },
};
</script>
  
  <style>
</style>
  
  