<template>
  <v-container :class="isMobile ? 'mx-0 px-0 my-1 py-1' : ''">
    <v-card
      :class="'mx-auto'"
      :style="'background-color: ' + paletColor.card.backgroundgral + ';'"
      elevation-6
      max-width="90%"
    >
      <v-card-title
        style="height: 80px"
        :style="'color: ' + paletColor.buttons.cancel.text + ';'"
      >
        <v-row>
          <v-col>{{ title }}</v-col>
          <v-col align="right" cols="1"
            ><v-btn
              :style="
                'background-color: ' + paletColor.card.backgroundgral + ';'
              "
              density="compact"
              icon="mdi-close-circle-outline"
              @click="volverMenu"
            ></v-btn
          ></v-col>
        </v-row>
      </v-card-title>
      <v-card-text :style="'background-color: ' + paletColor.colorNeutro + ';'">
        <div v-show="windowItem == 1">
          <buscadorDirecciones
            ref="buscadorDirecciones"
            @setearRutas="setearRutas"
            @continuaPasajeros="volverRegistroPasajetros"
            @setearServicioConvenio="setearServicioConvenio"
            :calculaCarrera="false"
          />
        </div>
        <div v-show="windowItem == 2">
          <alternativaRutas :data="data" @usarRuta="usarRuta" />
          <buttonCancelform
            labelSet="Volver"
            @accion="volverBuscaCarrera"
            preIcon="mdi-chevron-left"
          />
        </div>
        <div v-show="windowItem == 3">
          <registraPasajerosCarrera
            :ruta="ruta"
            :pasajerosSet="pasajeros"
            @volverAlternativaCarrera="volverAlternativaCarrera"
            @setearPasajeros="setearPasajeros"
          />
        </div>

        <div v-if="windowItem == 4">
          <registraDatosCarrera
            :data="data"
            :ruta="ruta"
            :carrera_cod="carrera"
            :pasajeros="pasajeros"
            :empresa_cod="empresa_cod"
            :servicio_cod="servicio_cod"
            :convenio_cod="convenio_cod"
            :cerrarCarrera="cerrarCarrera"
            @volverRegistroPasajetros="volverRegistroPasajetros"
          />
        </div>
      </v-card-text>
    </v-card>

    <mensajeUsuario ref="mensajeUsuario" />
    <mensajeUsuario ref="mensajeUsuarioVolver" @cerrar="volverMenu" />
    <mensajeUsuario ref="mensajeUsuarioSalir" @cerrar="SalirSistema" />
  </v-container>
</template>

<script>
import mensajeUsuario from "@/components/corss/mensajeUsuario.vue";
import buscadorDirecciones from "@/components/carrera/componentesCarrera/buscadorDirecciones.vue";
import alternativaRutas from "@/components/carrera/componentesCarrera/alternativaRutas.vue";
import registraPasajerosCarrera from "@/components/carrera/componentesCarrera/registraPasajerosCarrera.vue";
import buttonCancelform from "@/components/form/buttonCancelform.vue";
import registraDatosCarrera from "@/components/carrera/componentesCarrera/registraDatosCarrera.vue";
import { paletColor, obtenerJsonGET, isMobile } from "@/helpers/helpers";

export default {
  data: () => ({
    paletColor: paletColor,
    title: "Modificar Viaje",
    carrera: null,
    estado: null,
    windowItem: 2,
    data: null,
    empresa_cod: null,
    servicio_cod: null,
    convenio_cod: null,
    ruta: null,
    pasajeros: null,
    isMobile: isMobile(),
  }),
  props: {
    cerrarCarrera: Boolean,
  },

  computed: {
    // Clases condicionales para aplicar bordes o no
    cardClass() {
      return isMobile() ? "full-screen-card" : "default-card";
    },
    // Estilos en línea para que ocupe toda la pantalla en móvil
    cardStyle() {
      return isMobile() ? { margin: 0, width: "100%", height: "100vh" } : {};
    },
  },
  components: {
    mensajeUsuario,
    buscadorDirecciones,
    alternativaRutas,
    buttonCancelform,
    registraPasajerosCarrera,
    registraDatosCarrera,
  },
  mounted: async function () {
    if (sessionStorage.getItem("carrera")) {
      let car = JSON.parse(sessionStorage.getItem("carrera"));
      if (car) {
        this.carrera = car.id;
        this.estado = car.carrera_estado_id;

        this.ortenerDatosCarrera();
      } else {
        this.$refs.mensajeUsuarioVolver.mostrar(
          "No se ha definido el trayecto."
        );
      }
    } else {
      this.$refs.mensajeUsuarioVolver.mostrar("No se ha definido el trayecto.");
    }
  },
  methods: {
    setearPasajeros: function (pasajeros) {
      if (pasajeros) {
        this.pasajeros = pasajeros;
        this.windowItem = 4;
      } else {
        this.$refs.mensajeUsuario.mostrar(
          "Debe ingresar los pásajeros de la ruta."
        );
      }
    },
    setearRutas: async function (
      data,
      empresa_cod,
      servicio_cod,
      convenio_cod
    ) {
      if (data) {
        this.data = data;
        this.empresa_cod = empresa_cod;
        this.setearServicioConvenio(servicio_cod,convenio_cod)
        this.windowItem = 2;
      } else {
        this.$refs.mensajeUsuario.mostrar(
          "Debe tener al menos un origen y un destino."
        );
      }
    },
    
    setearServicioConvenio: async function(servicio_cod, convenio_cod){
      if(typeof servicio_cod == 'object' && servicio_cod){
        this.servicio_cod = servicio_cod.cod;
      }else{
        this.servicio_cod = servicio_cod;
      }
      if(typeof convenio_cod == 'object' && convenio_cod){
        this.convenio_cod = convenio_cod.cod;
      }else{
        this.convenio_cod = convenio_cod;
      }
    },

    usarRuta: async function (ruta) {
      if (ruta) {
        this.ruta = ruta;
        this.windowItem = 3;
      } else {
        this.$refs.mensajeUsuario.mostrar("Debe tener una ruta seleccionada.");
      }
    },
    ortenerDatosCarrera: async function () {
      let send = {
        carrera: this.carrera,
      };
      let data = await obtenerJsonGET("/carrera/obtenerdatoscarrera/", send);

      if (data.error == 0) {
        this.$refs.buscadorDirecciones.carrera_id = this.carrera;
        if (data.data.destinos) {
          this.$refs.buscadorDirecciones.asignaOrigen(
            data.data.destinos[0].origen
          );
          this.$refs.buscadorDirecciones.asignaDestino(
            data.data.destinos[0].destino,
            null,
            0
          );
          for (let x = 1; x < data.data.destinos.length; x++) {
            this.$refs.buscadorDirecciones.agregaDestino();
            this.$refs.buscadorDirecciones.asignaDestino(
              data.data.destinos[x].destino,
              null,
              x
            );
          }
        }
        this.$refs.buscadorDirecciones.fechahora = new Date(
          data.data.fechahora
        );
        let servicio = {};
        let convenio = {};
        if(data.data.servicio_cod){
          servicio = {
            cod: data.data.servicio_cod,
            desc: data.data.servicio_nombre,
          };
          this.$refs.buscadorDirecciones.asignarServicioEmpresa(servicio);
        }
        if(data.data.convenio_cod){
          convenio = {
            cod: data.data.convenio_cod,
            desc: data.data.convenio_nombre,
          };
          this.$refs.buscadorDirecciones.asignaConvenioEmpresa(convenio);
        }

        this.setearRutas(
          data.data,
          data.data.empresa_cod,
          data.data.servicio_cod,
          data.data.convenio_cod
        );
        this.usarRuta(data.data);
        this.setearPasajeros(data.data.pasajeros);
        if(this.cerrarCarrera){
          this.volverRegistroPasajetros();
        }else{
          this.volverBuscaCarrera();
        }
      } else {
        if (data.error == 1000) {
          this.$refs.mensajeUsuarioSalir.mostrar(data.message);
        } else {
          this.$refs.mensajeUsuarioVolver.mostrar(data.message);
        }
      }
    },
    volverRegistroPasajetros: function () {
      this.windowItem = 3;
    },
    volverAlternativaCarrera: function () {
      this.windowItem = 1;
    },
    volverBuscaCarrera: function () {
      this.windowItem = 1;
    },
    volverMenu: function () {
      this.$router.go(-1);
    },
    SalirSistema: function () {
      this.$router.replace("/");
    },
  },
};
</script>

<style>
</style>