<template>
  <v-app>
    <menu_superior v-if="router !='login' && router!=null"/>
    
    <v-main>
      <router-view />

      <v-footer align="right" class="text-caption"
        >Entorno ({{ entorno }}) - Versión ({{ version }})</v-footer
      >
    </v-main>
  </v-app>
</template>

<script>
import menu_superior from "@/components/start/menu_superior.vue";
import router from "./router";
export default {
  name: "App",
  data: () => ({
    usuario: {
      nombre: null,
      Token: null,
    },
    version: process.env.VUE_APP_VERSION,
    entorno: process.env.NODE_ENV,
    intervaloRevision: 1, //intervalo de revisión en segundos
    apiUrl: process.env.VUE_APP_API_URL,
    debugMode: process.env.VUE_APP_DEBUG_MODE === "true", // Convierte a booleano si es necesario
    router: null,
  }),
  components: {
    menu_superior,
  },
  watch: {
    "$route.name": function (newRouteName) {
      this.router = newRouteName;
      this.iniciarValidacionAutomatica();
    },
  },
  mounted() {
    // Iniciar el intervalo de validación automática
  },
  methods: {
    iniciarValidacionAutomatica() {
      // Ejecutar la validación cada 15 segundos (15000 ms)

      const usuario = JSON.parse(sessionStorage.getItem("usuarioDriverIn"));

      const vt = validaTiempo;
      if (vt(this.$route.name)) {
        this.intervalo = setInterval(
          () => vt(this.$route.name),
          process.env.VUE_APP_REVISA_SEGUNDOS_SIN_ACTIVIDAD * 1000
        ); // 15 segundos
      }
      if(this.$route.name !== 'login' && !usuario){
        this.$router.replace("/");
      }
    },
  },
};

function validaTiempo(routeName) {
  if (routeName !== "login") {
    if (validarUltimoLlamado()) {
      sessionStorage.removeItem("usuarioDriverIn"); // Elimina la sesión
      this.$router.replace("/");
      return false;
    }
  }
  return true;
}

function validarUltimoLlamado() {
  const usuario = JSON.parse(sessionStorage.getItem("usuarioDriverIn"));
  if (usuario) {
    const fechaUltimoLlamado = new Date(usuario.FechaUltimoLlamado);
    const ahora = new Date();
    const diferenciaEnMinutos = (ahora - fechaUltimoLlamado) / (1000 * 60);
    const minutosSinActividad =
      process.env.VUE_APP_MAXIMO_MINUTOS_SIN_ACTIVIDAD;
    if (diferenciaEnMinutos > minutosSinActividad) {
      return false;
    } else if (!usuario.Token) {
      return false;
    }
  } else {
    return false;
  }
  return true;
}
</script>

<style>
.captcha,
.v-label,
.v-field-label,
.label,
.v-label--clickable,
.v-selection-control {
  font-size: 12px !important;
}

.v-label {
  font-size: 12px;
}
.v-input__details {
  font-size: 12px;
}
.v-field__input {
  font-size: 12px;
}
</style>