<template>
  <v-text-field
    :clearable="clearable"
    :label="label"
    variant="outlined"
    :autofocus="autofocus"
    ref="texto"
    v-model="valor"
    density="compact"
    autocomplete="false"
    :prepend-inner-icon="icono"
    :placeholder="textoAlternativo"
    :hint="MensajeAyuda"
    :readonly="readonly"
    :loading="validating"
    :disabled="validating"
    :maxLength="maxLength"
    :rules="[
      this.requerido && rules.requerido ? rules.requerido : null,
      this.valor != null && this.minLength > this.valor.length
        ? 'Mínimo ' + this.separadorMiles(this.minLength) + ' digitos.'
        : null,
      this.valor != null && this.maxLength < this.valor.length
        ? 'Máximo ' + this.separadorMiles(this.maxLength) + ' digitos.'
        : null,
    ]"
    @blur="perderFoco"
  ></v-text-field>
</template>
  
  <script>

export default {
  data: () => ({
    clearable: true,
    valor: null,
    validating: false,
    label: "Texto",
    icono: null,
    textoAlternativo: null,
    MensajeAyuda: null,
    autofocus: false,
    requerido: false,
    readonly: false,
    minLength: 0,
    maxLength: 50,
    rules: {
      requerido(v) {
        if (!v) {
          return "Es requerido.";
        }
      },
    },
  }),
  props: {
    valorSet: String,
    validatingSet: Boolean,
    labelSet: String,
    autofocusSet: Boolean,
    requeridoSet: Boolean,
    readonlySet: Boolean,
    minLengthSet: Number,
    maxLengthSet: Number,
    iconoSet: String,
    textoAlternativoSet: String,
    MensajeAyudaSet: String,
    focus: Boolean,
    idExterno: Number,
  },
  watch: {
    valorSet: function (val) {
      this.valor = val;
    },
    validatingSet: function (val) {
      this.validating = val;
    },
    labelSet: function (val) {
      this.label = val;
    },
    autofocusSet: function (val) {
      this.autofocus = val;
    },
    requeridoSet: function (val) {
      this.requerido = val;
    },
    readonlySet: function (val) {
      this.readonly = val;
      if (this.readonly) {
        this.clearable = false;
      } else {
        this.clearable = false;
      }
    },
    minLengthSet: function (val) {
      this.minLengthSet = val;
    },
    maxLengthSet: function (val) {
      this.maxLength = val;
    },
    iconoSet: function (val) {
      this.icono = val;
    },
    textoAlternativoSet: function (val) {
      this.textoAlternativo = val;
    },
    MensajeAyudaSet: function (val) {
      this.MensajeAyuda = val;
    },
    focus: function (val) {
      if (val) {
        this.$refs.Email.focus();
      }
    },
    valor: function (val) {
      this.$emit("SetValor", val);
    },
  },
  mounted: function () {
    if (this.valorSet) {
      this.valor = this.valorSet;
    }
    if (this.validatingSet) {
      this.validating = this.validatingSet;
    }
    if (this.labelSet) {
      this.label = this.labelSet;
    }
    if (this.autofocusSet) {
      this.autofocus = this.autofocusSet;
    }
    if (this.requeridoSet) {
      this.requerido = this.requeridoSet;
    }
    if (this.readonlySet) {
      this.readonly = this.readonlySet;
      if (this.readonly) {
        this.clearable = false;
      } else {
        this.clearable = true;
      }
    }
    if (this.minLengthSet) {
      this.minLength = this.minLengthSet;
    }
    if (this.maxLengthSet) {
      this.maxLength = this.maxLengthSet;
    }
    if (this.iconoSet) {
      this.icono = this.iconoSet;
    }
    if (this.textoAlternativoSet) {
      this.textoAlternativo = this.textoAlternativoSet;
    }
    if (this.MensajeAyudaSet) {
      this.MensajeAyuda = this.MensajeAyudaSet;
    }
  },
  methods: {
    perderFoco: function (obj) {
      this.$emit("perderFoco", this.valor,this.idExterno);
    },
    
    separadorMiles: function (numero) {
      // Validar que el número sea un valor numérico
      if (isNaN(numero)) {
        return "0";
      }
      numero = parseFloat(numero);
      // Usar toLocaleString para formatear el número con separador de miles
      return numero.toLocaleString("es-CL");
    },
  },
};
</script>
  
  <style>
</style>