<template>
    <modificaCarrera ref="modificaCarrera" :cerrarCarrera="true"/>
  </template> 
  
  <script>
  import modificaCarrera from './modificaCarrera.vue';
  export default {
    components: {
        modificaCarrera
    },
    mounted: function(){
        this.$refs.modificaCarrera.title = "Terminar Viaje";
    }
  };
  </script>
  
  <style>
  </style>