<template>
  <v-btn
      :style="'background-color:'+background_color+';color: '+color+' ;font-size: 12px;'"
    dark
    block
    title
    @click="cerrar(0)"
    :loading="validating"
    :prepend-icon="icon"
    >{{ label }}</v-btn
  >
</template>

<script>
import { paletColor } from "@/helpers/helpers";
export default {
  data: () => ({
    label: "Cerrar",
    icon: null,
    paletColor: paletColor,
    background_color:paletColor.buttons.cancel.background,
    color: paletColor.buttons.cancel.text,
  }),
  props: {
    validating: Boolean,
    labelSet: String,
    preIcon: String,
  },
  watch: {
    labelSet: function(val){
      this.laber = val;
    },
    preIcon: function(val){
      this.icon = val;
    }
  },
  mounted: function(){
    if(this.labelSet){
      this.label = this.labelSet;
    }
    if(this.preIcon){
      this.icon = this.preIcon;
    }
  },
  methods: {
    cerrar: function (id) {
      this.$emit("cerrar", id);
      this.accion();
    },
    accion: function () {
      this.$emit("accion");
    },
  },
};
</script>

<style>
</style>