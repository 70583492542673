module.exports.paletColor = {
  textoGeneral: "#555555",
  card: {
    backgroundgral: "#2C3E50",
  },
  colorFondoAlternativo: "#EBEFF4",
  colorFondoAlternativo2: "#B9C8D9",
  colorPrincipal: "#2C3E50",
  colorSecundario: "#1ABC9C",
  colorDeAcento: "#E74C3C",
  colorTexto: "#34495E",
  colorNeutro: "#ECF0F1",
  buttons: {
    submit: {
      background: "#d26e4b",
      text: "#EBEFF4",
    },
    cancel: {
      background: "#446084",
      text: "#EBEFF4",
    },
  },
};

module.exports.sizeText = {
  card: {
    lista: {
      destacado: {
        size: 16,
      }
    }
  }
}

module.exports.isMobile = function() {
  return /Android|iPhone|iPad|iPod|Opera Mini|IEMobile/i.test(
    navigator.userAgent
  );
}

/**
 * Permite hacer un llamado ajax con metodo GET
 * @param {*} urlSend Corresponde a la URL relativa del servidor donde hacer el llamado
 * @param {*} SendData datos que se desean enviar (debe ser en modo objeto javascript)
 * @returns
 */
module.exports.obtenerJsonGET = async function (urlSend, SendData) {
  URLSend = process.env.VUE_APP_API_URL + urlSend;

  var returnData = {
    error: 0,
    message: "",
    data: "",
  };
  try {
    var user = null;

    if (sessionStorage.getItem("usuarioDriverIn")) {
      user = JSON.parse(sessionStorage.getItem("usuarioDriverIn"));
    } else {
      user = {
        token: null,
      };
    }

    if (!user) {
      user = {
        token: null,
      };
    }

    var dataSend = {
      token: user.token,
      mode: "cors",
      modo: process.env.VUE_APP_VERSION,
    };
    for (const property in SendData) {
      dataSend[property] = SendData[property];
    }
    const url = new URL(URLSend);
    url.search = new URLSearchParams(dataSend);

    const data = await fetch(url);
    const json = await data.json();
    returnData = json;
  } catch (err) {
    returnData.error = -1;
    returnData.message =
      "Esto es un error al intentar solicitar información GET: " + err;
  }
  return returnData;
};

/**
 * Permite hacer un llamado ajax con metodo POST
 * @param {*} urlSend Corresponde a la URL relativa del servidor donde hacer el llamado
 * @param {*} data2 datos que se desean enviar (debe ser en modo objeto FormData())
 * @returns
 */
module.exports.enviarJsonPOST = async function (urlSend, data2) {
  URLSend = process.env.VUE_APP_API_URL + urlSend;
  var returnData = {
    error: 0,
    message: "",
    data: "",
  };

  try {
    if (sessionStorage.getItem("usuarioDriverIn")) {
      user = JSON.parse(sessionStorage.getItem("usuarioDriverIn"));
    } else {
      user = null;
    }

    if (user) {
      if (user.token) {
        data2.append("token", user.token);
      } else {
        data2.append("token", null);
      }
    } else {
      data2.append("token", null);
    }

    const setting = {
      method: "POST",
      modo: process.env.VUE_APP_VERSION,
      body: data2,
    };
    const resp = await fetch(URLSend, setting);
    if (!resp.ok || resp.headers.get("Content-Type") != "application/json") {
      throw new Error("Algo Salio mal");
    }
    const json = await resp.json();
    return json;
  } catch (err) {
    returnData.error = -1;
    returnData.message =
      "Esto es un error al intentar solicitar información POST: " + err;
  }
  return returnData;
};

module.exports.formatoMonedaChile = function (numero, decimales) {
  if (isNaN(numero)) {
    return null;
  }

  // Formatear como moneda chilena
  return new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
    minimumFractionDigits: decimales,
  }).format(numero);
};

/**
 *
 * @param {String} val Fecha en formato 'YYYY-MM-DD HH:II:SS'
 * @param {boolean} bool verdadero si equiere segundos, falso si no.
 * @returns
 */
module.exports.formatoFechaHoraTXT = function (val) {
  let f = new Date(val);
  const anio = f.getFullYear();
  const mes = String(f.getMonth() + 1).padStart(2, "0");
  const dia = String(f.getDate()).padStart(2, "0");
  const hora = String(f.getHours()).padStart(2, "0");
  const minutos = String(f.getMinutes()).padStart(2, "0");
  const segundos = String(f.getSeconds()).padStart(2, "0");

  let fTXT = "";
  if (arguments > 1) {
    if (arguments[1]) {
      fTXT = `${dia}-${mes}-${anio} ${hora}:${minutos}:${segundos}`;
    } else {
      fTXT = `${dia}-${mes}-${anio} ${hora}:${minutos}`;
    }
  } else {
    fTXT = `${dia}-${mes}-${anio} ${hora}:${minutos}:${segundos}`;
  }

  return fTXT;
};

module.exports.formatoFechaHoraVue = function (date) {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let hour = date.getHours();
  let minute = date.getMinutes();
  if (month > 12) {
    month = 1;
    year++;
  }

  day = "" + (day < 10 ? "0" : "") + day;
  month = "" + (month < 10 ? "0" : "") + month;
  year = "" + (year < 999 ? "0" : "") + year;
  hour = "" + (hour < 10 ? "0" : "") + hour;
  minute = "" + (minute < 10 ? "0" : "") + minute;

  let fechaText = `${year}-${month}-${day} ${hour}:${minute}`;

  return fechaText;
};

module.exports.isMobile = function () {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Detectar dispositivos móviles y tabletas
  if (/android/i.test(userAgent)) {
    return true;
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true;
  }

  return false;
};
