<template>
  <loginForm />
</template>

<script>
import loginForm from "@/components/seguridad/loginForm.vue";
export default {
  components: {
    loginForm,
  },
};
</script>

<style>
</style>