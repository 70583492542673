<template>
  <v-container :class="isMobile ? 'px-0 py-0' : ''">
    <v-form
      ref="form_ingreso"
      v-model="validForm"
      @submit.prevent="calcularCarrera"
    >
      <div class="text-h6">Viaje</div>
      <v-row dense class="text-caption">
        <v-col cols="12" class="mt-4">
          <cbo_combobox
            v-show="empresa_item.length > 1"
            ref="empresa_cod"
            labelSet="Empresa"
            :valorSet="empresa_cod"
            :itemsSet="empresa_item"
            :maxLengthSet="100"
            :minLengthSet="3"
            :requeridoSet="true"
            :validatingSet="validating"
          />
        </v-col>
        <v-col cols="12" v-show="servicio_item.length > 1">
          <cbo_combobox
            ref="servicio_cod"
            labelSet="Servicio"
            :valorSet="servicio_cod"
            :itemsSet="servicio_item"
            :requeridoSet="true"
            :maxLengthSet="100"
            :minLengthSet="3"
            :validatingSet="validating"
          />
        </v-col>
        <v-col cols="12">
          <cbo_combobox
            ref="convenio_cod"
            labelSet="Convenio"
            :valorSet="convenio_cod"
            :itemsSet="convenio_item"
            :maxLengthSet="100"
            :minLengthSet="3"
            :requeridoSet="false"
            MensajeAyudaSet="Puede quedar vacio o escribir uno nuevo"
            :validatingSet="validating"
            @setValor="asignaConvenioEmpresa"
          />
        </v-col>
        <v-col cols="10">
          <txt_origen
            ref="txt_origen"
            labelSet="Origen"
            :valorSet="origen"
            :validatingSet="validating"
            :requeridoSet="true"
            :maxLengthSet="200"
            :minLengthSet="10"
            @keyup="AsignaValorOrigen"
          />
        </v-col>
        <v-col cols="2" align="right" justify-end>
          <v-btn
            density="comfortable"
            icon="mdi-google-maps"
            @click="abrirMapaCarrera('origen')"
            :loading="validating"
          ></v-btn>
        </v-col>
        <v-col cols="12" dense no-gutters v-if="itemsGoogleOrigen">
          <v-row dense>
            <v-col
              cols="12"
              md="6"
              class="clickable v-col-button"
              v-for="(resultado, index) in itemsGoogleOrigen"
              :key="index"
            >
              <v-chip @click="asignaOrigen(resultado.title, resultado.value)">{{
                resultado.title
              }}</v-chip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        dense
        class="text-caption"
        v-for="(destino, index) in destinos"
        :key="index"
      >
        <v-col cols="10">
          <txt_origen
            ref="txt_destino"
            :labelSet="'Destino ' + (index > 0 ? index + 1 : '')"
            :idExterno="index"
            :requeridoSet="index == 0 ? true : false"
            :valorSet="destinos[index]"
            :validatingSet="validating"
            :textoAlternativoSet="'Destino ' + (index > 0 ? index + 1 : '')"
            :maxLengthSet="200"
            :minLengthSet="10"
            @keyup="AsignaValorDestino(index)"
          />
        </v-col>
        <v-col cols="2" align="right" justify-end>
          <v-btn
            density="comfortable"
            icon="mdi-google-maps"
            @click="abrirMapaCarrera('destino', index)"
            :loading="validating"
          ></v-btn>
        </v-col>
        <v-col cols="12" dense no-gutters v-if="itemsGoogleDestino[index]">
          <v-row dense>
            <v-col
              cols="12"
              md="6"
              class="clickable v-col-button"
              v-for="(resultado, index2) in itemsGoogleDestino[index]"
              :key="index2"
            >
              <v-chip
                @click="asignaDestino(resultado.title, resultado.value, index)"
                >{{ resultado.title }}</v-chip
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row dense class="text-caption">
        <v-col cols="12">
          <v-chip

            style="font-size: 11px"
            append-icon="mdi-sign-direction-plus"
            @click="agregaDestino"
            v-if="!validating"
          >
            Agregar mas destinos
          </v-chip>
        </v-col>
        <v-col cols="12" class="mt-3">
          <txt_fechacarrera
            ref="txt_fechacarrera"
            :validatingSet="validating"
            :valorSet="fechaActual"
            :minFecSet="fechaActual"
            :maxFecSet="fechaMaxima"
            :requeridoSet="true"
          />
        </v-col>
      </v-row>
      <v-row>
        <!--
      Esto lo muestra sólo si es mobile
      -->
        <v-col cols="12" v-if="isMobile && calculaCarreraLocal">
          <buttonSubmitform
            :labelSet="(parseInt(carrera_id)>0?'Re-':'')+'Calcula viaje'"
            :validating="validating"
            append-icon="mdi-chevron-right"
          />
        </v-col>
        <v-col :cols="isMobile ? 12 : 6" v-if="!calculaCarreraLocal">
          <buttonCancelform
            append-icon="mdi-chevron-right"
            labelSet="Ir a pasajeros"
            :validating="validating"
            @accion="continuaPasajeros"
          />
        </v-col>
        <!--
      Esto lo muestra sólo si NO es mobile
      -->
        <v-col cols="6" v-if="!isMobile && calculaCarreraLocal">
          <buttonSubmitform
            v-if="calculaCarreraLocal"
            :labelSet="(parseInt(carrera_id)>0?'Re-':'')+'Calcula viaje'"
            :validating="validating"
            append-icon="mdi-chevron-right"
          />
        </v-col>
        <v-col cols="12" v-if="mensajeRespuestaRuta" style="color: red">
          {{ mensajeRespuestaRuta }}
        </v-col>
      </v-row>
    </v-form>

    <mapa_carrera
      ref="mapa_carrera"
      :titulo="tituloMapaCarrera"
      @seleccionMaps="seleccionMaps"
    />
    <mensajeUsuario ref="mensajeUsuarioSalir" @cerrar="salirApp" />
    <mensajeUsuario ref="mensajeUsuario" />
  </v-container>
</template>

<script>
import mensajeUsuario from "@/components/corss/mensajeUsuario.vue";
import txt_origen from "@/components/form/textos.vue";
import buttonCancelform from "@/components/form/buttonCancelform.vue";
import buttonSubmitform from "@/components/form/buttonSubmitform.vue";
import mapa_carrera from "@/components/carrera/mapa_carrera.vue";
import txt_fechacarrera from "@/components/form/fechaHora.vue";
import cbo_combobox from "@/components/form/combobox.vue";
import { enviarJsonPOST, isMobile, obtenerJsonGET } from "@/helpers/helpers";
import { createLogger } from "vuex";

export default {
  name: "buscadorDirecciones",

  data: () => ({
    timeoutId: null,
    itemsGoogleOrigen: null,
    itemsGoogleDestino: [],

    validForm: null,
    validating: null,
    origen: null,
    origen_lat: null,
    origen_lng: null,
    destinos: [""],
    destinos_lat: [""],
    destinos_lng: [""],
    overview_polyline_points: [""],
    mensajeRespuestaRuta: null,
    tituloMapaCarrera: null,
    fechayhora: null,
    fechaActual: null,
    fechaMaxima: null,
    empresa_cod: null,
    empresa_item: [],
    servicio_cod: null,
    servicio_item: [],
    convenio_cod: null,
    convenio_item: [],
    data: null,
    calculaCarreraLocal: true,
    isMobile: isMobile(),
    carrera_id: null,
  }),
  components: {
    mensajeUsuario,
    txt_origen,
    buttonSubmitform,
    mapa_carrera,
    txt_fechacarrera,
    cbo_combobox,
    buttonCancelform,
  },
  props: {
    calculaCarrera: Boolean,
  },
  watch: {
    calculaCarrera: function (val) {
      this.calculaCarreraLocal = val;
    },
  },
  mounted: function () {
    let fecha = new Date();
    let milisegundosEn24Horas = 24 * 60 * 60 * 1000;
    this.fechaMaxima = new Date(fecha.getTime() + milisegundosEn24Horas);
    milisegundosEn24Horas = 0.25 * 60 * 60 * 1000;
    this.fechaActual = new Date(fecha.getTime() + milisegundosEn24Horas);
    this.obtenerEmpresas();
    this.calculaCarreraLocal = this.calculaCarrera;
  },
  methods: {
    continuaPasajeros: function () {
      this.$emit("continuaPasajeros");
    },
    asignaOrigen: function (val, id) {
      this.origen = val;
      this.itemsGoogleOrigen = null;
    },
    asignaDestino: function (val, id, index) {
      this.destinos[index] = val;
      this.itemsGoogleDestino[index] = [];
    },
    AsignaValorOrigen: function (val) {
      this.origen = this.$refs.txt_origen.valor;
      this.calculaCarreraLocal = true;

      if (this.timeoutId) clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(
        () => buscaDireccion(this, this.origen),
        1000
      );
    },
    AsignaValorDestino: function (index) {
      this.destinos[index] = this.$refs.txt_destino[index].valor;
      this.calculaCarreraLocal = true;

      if (this.timeoutId) clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(
        () => buscaDireccionDestino(this, this.destinos[index], index),
        1000
      );
    },
    /*AsignaValorDestino: function (val, index) {
      this.destinos[index] = val ? val : "";
    },*/
    onInput: async function (value) {
      // Aquí puedes manejar la lógica al seleccionar una opción

      let txt = this.$refs.selectedItemGoogleOrigen.value;

      if (this.timeoutId) clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => buscaDireccion(this, txt), 1000);
    },

    obtenerEmpresas: async function () {
      let data = await obtenerJsonGET("/empresa/obtener_empresas/");
      if (data.error == "0") {
        this.empresa_item = data.data;
        if (data.data.length == 1) {
          this.empresa_cod = data.data[0];
          this.obtenerServicioEmpresa(this.empresa_cod.cod);
          this.obtenerConveniosEmpresa(this.empresa_cod.cod);
        }
      } else {
        if (data.error == 1000) {
          this.$refs.mensajeUsuarioSalir.mostrar(data.message);
        } else {
          this.$refs.mensajeUsuario.mostrar(data.message);
        }
      }
    },
    asignarServicioEmpresa: async function (servicio) {
      let servicio_cod = null;
      if (servicio) {
        this.servicio_cod = servicio;
        if (this.$refs.convenio_cod) {
          if (this.$refs.convenio_cod.valor) {
            if (this.$refs.convenio_cod.valor.cod) {
              servicio_cod = this.$refs.convenio_cod.valor.cod;
            } else {
              servicio_cod = this.$refs.convenio_cod.valor;
            }
          }
        }
        this.$emit("setearServicioConvenio",this.servicio_cod,this.convenio_cod)
      }
    },
    obtenerServicioEmpresa: async function (cod_empresa) {
      let env = {
        empresa: cod_empresa,
      };
      let data = await obtenerJsonGET(
        "/servicio/obtener_servicio_empresa_combobox/",
        env
      );
      if (data.error == "0") {
        this.servicio_item = data.data;
        if (data.data.length == 1) {
          this.asignarServicioEmpresa(data.data[0]);
        }
      } else {
        if (data.error == 1000) {
          this.$refs.mensajeUsuarioSalir.mostrar(data.message);
        } else {
          this.$refs.mensajeUsuario.mostrar(data.message);
        }
      }
    },
    asignaConvenioEmpresa: async function (convenio) {
      let convenio_cod = null;
      if (convenio) {
        this.convenio_cod = convenio;
        if (this.$refs.convenio_cod) {
          if (this.$refs.convenio_cod.valor) {
            if (this.$refs.convenio_cod.valor.cod) {
              convenio_cod = this.$refs.convenio_cod.valor.cod;
            } else {
              convenio_cod = this.$refs.convenio_cod.valor;
            }
          }
        }
        this.$emit("setearServicioConvenio",this.servicio_cod,convenio_cod)
      }
    },
    obtenerConveniosEmpresa: async function (cod_empresa) {
      let env = {
        empresa: cod_empresa,
      };
      let data = await obtenerJsonGET("/convenio/listaconveniosempresas/", env);
      if (data.error == "0") {
        this.convenio_item = data.data;
      } else {
        if (data.error == 1000) {
          this.$refs.mensajeUsuarioSalir.mostrar(data.message);
        } else {
          this.$refs.mensajeUsuario.mostrar(data.message);
        }
      }
    },
    agregaDestino: function (val) {
      if (this.destinos.length < 15) {
        this.destinos.push("");
        this.destinos_lat.push("");
        this.destinos_lng.push("");
        this.overview_polyline_points.push("");
      } else {
        this.$refs.mensajeUsuario.mostrar("ah superado el máximo de capacidad");
      }
    },
    abrirMapaCarrera: function (titulo) {
      this.tituloMapaCarrera = titulo;
      if (this.tituloMapaCarrera == "origen") {
        this.$refs.mapa_carrera.aperturaMapa("origen", {
          lat: this.origen_lat,
          lng: this.origen_lng,
        });
      } else if (this.tituloMapaCarrera == "destino") {
        if (arguments.length == 2) {
          this.$refs.mapa_carrera.aperturaMapa(
            "destino",
            {
              lat: this.destinos_lat[arguments[1]],
              lng: this.destinos_lng[arguments[1]],
            },
            arguments[1]
          );
        } else {
          this.$refs.mensajeUsuario.mostrar("error al intentar abrir mapa");
        }
      }
    },
    seleccionMaps: function () {
      if (arguments.length > 0) {
        if (arguments[2] == "origen") {
          this.origen = arguments[0] + "," + arguments[1];
          this.origen_lat = arguments[0];
          this.origen_lng = arguments[1];
        } else if (arguments[2] == "destino") {
          this.destinos[arguments[3]] = arguments[0] + "," + arguments[1];
          this.destinos_lat[arguments[3]] = arguments[0];
          this.destinos_lng[arguments[3]] = arguments[1];
        }
        this.openMapacarrera = false;
      } else {
        this.$refs.mensajeUsuario.mostrar("error al intentare cerrar el mapa.");
      }
    },
    calcularCarrera: async function () {
      if (this.calculaCarreraLocal) {
        const { valid } = await this.$refs.form_ingreso.validate();
        if (valid) {
          let destinorev = [];
          let destino_latrev = [];
          let destino_lngrev = [];
          let overview_polyline_pointsrev = [];
          for (let x = 0; x < this.destinos.length; x++) {
            if (this.destinos[x] != "") {
              destinorev.push(this.destinos[x]);
              destino_latrev.push(this.destinos_lat[x]);
              destino_lngrev.push(this.destinos_lng[x]);
            }
          }
          this.destinos = destinorev;
          this.destinos_lat = destino_latrev;
          this.destinos_lng = destino_lngrev;
          this.overview_polyline_points = overview_polyline_pointsrev;
          if (this.destinos.length > 0) {
            this.overview_polyline_points = null;
            this.overview_polyline_points = Array(destinorev.length);
            this.validating = true;
            this.mensajeRespuestaRuta = null;
            this.fechayhora = this.$refs.txt_fechacarrera.valor;
            this.data = [];
            if (this.origen && this.destinos) {
              let fd = new FormData();

              let convenio_cod = "";
              if (this.$refs.convenio_cod) {
                if (this.$refs.convenio_cod.valor) {
                  if (this.$refs.convenio_cod.valor.cod) {
                    convenio_cod = this.$refs.convenio_cod.valor.cod;
                  } else {
                    convenio_cod = this.$refs.convenio_cod.valor;
                  }
                }
              }

              fd.append("empresa", this.$refs.empresa_cod.valor.cod);
              fd.append("servicio", this.$refs.servicio_cod.valor.cod);
              fd.append("convenio", convenio_cod);
              fd.append("origen", this.origen);
              for (let x = 0; x < this.destinos.length; x++) {
                fd.append("destino[]", this.destinos[x]);
              }
              fd.append("departureTime", this.fechayhora);
              let data = await enviarJsonPOST("/Serviciosexternos/mapa/", fd);
              if (data) {
                if (data.error == 0) {
                  this.data = data.data;
                  if (data.data.routes.length > 0) {
                    if (data.data.routes[0].legs.length > 0) {
                      this.origen =
                        data.data.routes[0].legs[0].origen.direccion;
                      for (
                        let dir = 0;
                        dir < data.data.routes[0].legs.length;
                        dir++
                      ) {
                        this.destinos[dir] =
                          data.data.routes[0].legs[dir].destino.direccion;
                        this.destinos_lat[dir] =
                          data.data.routes[0].legs[dir].destino.lat;
                        this.destinos_lng[dir] =
                          data.data.routes[0].legs[dir].destino.lng;
                      }
                      if (this.$refs.empresa_cod) {
                        this.empresa_cod = this.$refs.empresa_cod.valor;
                      }
                      if (this.$refs.servicio_cod) {
                        this.servicio_cod = this.$refs.servicio_cod.valor;
                      }

                      this.$emit(
                        "setearRutas",
                        this.data,
                        this.empresa_cod.cod,
                        this.servicio_cod.cod,
                        convenio_cod
                      );
                    } else {
                      this.mensajeRespuestaRuta =
                        "No se encontraron alternativas, revise que las direcciones sean correctas";
                    }
                  } else {
                    this.mensajeRespuestaRuta =
                      "No se encontraron alternativas, revise que las direcciones sean correctas";
                  }
                } else {
                  if (data.error == 1000) {
                    this.$refs.mensajeUsuarioSalir.mostrar(data.message);
                  } else {
                    this.mensajeRespuestaRuta = data.message;
                  }
                }
              } else {
                this.$refs.mensajeUsuarioSalir.mostrar(
                  "Servicio no responde. el sistema requerirá que vuelva a autenticarse."
                );
              }
            } else {
              this.$refs.mensajeUsuario.mostrar(
                "debe seleciconar origen y destino"
              );
            }
            this.validating = false;
          } else {
            this.agregaDestino();
            this.$refs.mensajeUsuario.mostrar(
              "debe ingresar al menos 1 destino"
            );
          }
        } else {
          this.$refs.mensajeUsuario.mostrar(
            "Debe ingresar toda la información requerida.",
            "Advertencia"
          );
        }
      } else {
        alert("no calcula carrera");
      }
    },
    salirApp: function () {
      this.$router.replace("/");
    },
  },
};

const buscaDireccion = async function (obj, text) {
  if (text.length > 4) {
    let fnd = {
      direccion: text,
    };
    let data = await obtenerJsonGET(
      "/Serviciosexternos/busca_texto_direccion/",
      fnd
    );
    if (data.error === 0) {
      obj.itemsGoogleOrigen = data.data;
    } else {
      obj.itemsGoogleOrigen = null;
    }
  } else {
    obj.itemsGoogleOrigen = null;
  }
};
const buscaDireccionDestino = async function (obj, text, index) {
  if (text.length > 4) {
    let fnd = {
      direccion: text,
    };
    let data = await obtenerJsonGET(
      "/Serviciosexternos/busca_texto_direccion/",
      fnd
    );
    if (data.error === 0) {
      obj.itemsGoogleDestino[index] = data.data;
    } else {
      obj.itemsGoogleDestino[index] = null;
    }
  } else {
    obj.itemsGoogleDestino[index] = null;
  }
};
</script>

<style>
</style>