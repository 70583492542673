<template>
  <v-app-bar
    app
    flat
    :style="'background-color: ' + paletColor.colorPrincipal + ';'"
  >
    <!-- Botón para el menú lateral -->
    <v-app-bar-nav-icon @click="toggleDrawer" :style="'color: ' + paletColor.buttons.cancel.text + ';'"></v-app-bar-nav-icon>

    <!-- Imagen del logo alineada a la izquierda -->
    <v-img
      src="/img/start/LogoDriverInNombreBlanco.gif"
      alt="Logo"
      height="50"
      class="mx-3"
    ></v-img>

    <!-- Espacio para empujar el botón de cerrar sesión a la derecha -->
    <v-spacer></v-spacer>

    <!-- Botón de cerrar sesión con ícono -->
     <div v-html="formattedNombre()" :style="'color: ' + paletColor.buttons.cancel.text + ';font-size: 13px;'"></div>
    <v-btn icon @click="logout" :style="'color: ' + paletColor.buttons.cancel.text + ';'">
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>

  <!-- Menú lateral que se despliega -->
  <v-navigation-drawer
    v-model="drawer"
    app
    temporary
    :style="'background-color: ' + paletColor.colorFondoAlternativo + ';'"
  >
  <div v-if="usuario" class="pt-4 pb-4">
    <v-icon>mdi-account-tie</v-icon>
  {{ usuario.nombre }}
  </div>
  <v-divider/>
    <v-list dense>
      <v-list-item-group dense>
        <v-list-item
          v-for="item in menuItems"
          :key="item.text"
          @click="goPage(item.url)"
        >
          <v-list-item-content class="d-flex align-center">
            <!-- Ícono y texto en la misma línea -->
            <v-icon>{{ item.icon }}</v-icon>
            <v-list-item-title class="ml-3">{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
  
</template>

<script>
import { paletColor } from "@/helpers/helpers";
export default {
  name: "menu_superior",

  data: () => ({
    usuario: JSON.parse(sessionStorage.getItem("usuarioDriverIn")),
    apiUrl: process.env.VUE_APP_API_URL,
    debugMode: process.env.VUE_APP_DEBUG_MODE === "true", // Convierte a booleano si es necesario
    drawer: false, // Estado del menú lateral
    paletColor: paletColor,
    menuItems: [
    { text: "Mis Viajes", icon: "mdi-home", url: "/start" },
      { text: "Nuevo Viaje", icon: "mdi-sign-direction-plus", url: "/registraNuevaCarrera" },
      { text: "Nueva carga combustible", icon: "mdi-water-plus", url: "/nuevocombustible" },
      { text: "Nuevo gasto", icon: "mdi-sign-direction-plus", url: "/start" },
      //{ text: "Perfil", icon: "mdi-account", url: "/listacarreras" },
      //{ text: "Ajustes", icon: "mdi-cog", url: "https://www.google.cl" },
    ], // Opciones del menú lateral
  }),
  components: {
    
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer; // Alternar el estado del menú lateral
    },
    logout() {
      // Función para cerrar sesión
      sessionStorage.removeItem("usuarioDriverIn"); // Elimina la sesión
      this.$router.replace("/")
    },
    goPage: function (page) {
      if (page == "/") {
        window.location.replace("/");
      } else {
        this.$router.push(page);
      }
    },
    formattedNombre() {
      // Separar el texto por espacios y convertirlo en un arreglo
      const words = this.usuario.nombre.split(' ');

      // Tomar los dos primeros elementos y unirlos con un <br>
      const formattedString = words.slice(0, 2).join('<br>');

      return formattedString;
    }
  },
};
</script>

<style>
.app-bar {
  background-color: #2c3e50; /* Color del app bar */
  color: #ecf0f1; /* Color del texto */
}
</style>