<template>
  <v-container> listado de carreras </v-container>
</template>

<script>
export default {
  mounted: function () {
    const usuario = JSON.parse(sessionStorage.getItem("usuarioDriverIn"));

    if (usuario) {
      usuario.FechaUltimoLlamado = new Date();
      sessionStorage.setItem("usuarioDriverIn", JSON.stringify(usuario));
    }
  },
};
</script>

<style>
</style>