<template>
  <v-container :class="isMobile?'mx-0 px-0 my-1 py-1':''">
    <v-card
      :class="'mx-auto'"
      :style="'background-color: ' + paletColor.card.backgroundgral + ';'"
      elevation-6
      max-width="90%"
    >
    <v-card-title
        style="height: 80px"
        :style="'color: ' + paletColor.buttons.cancel.text + ';'"
      >
        <v-row>
          <v-col>{{ title }}</v-col>
          <v-col align="right" cols="1"
            ><v-btn
              :style="
                'background-color: ' + paletColor.card.backgroundgral + ';'
              "
              density="compact"
              icon="mdi-close-circle-outline"
              @click="volverMenu"
            ></v-btn
          ></v-col>
        </v-row>
      </v-card-title>
      
      <v-card-text :style="'background-color: ' + paletColor.colorNeutro + ';'">
        <cmp_combustible/>
      </v-card-text>
</v-card>  
  </v-container>
</template>

<script>
import {
  obtenerJsonGET,
  paletColor,
  isMobile,
} from "@/helpers/helpers";
import cmp_combustible from "@/components/combustible/componentesCombustible/combustible.vue";
export default {
  data: () => ({
    title: "Carga Combustible",
    isMobile: isMobile(),
    paletColor:paletColor,
  }),
  components: {
    cmp_combustible,
  },
  methods:{
    volverMenu: function () {
      this.$router.go(-1);
    },
    SalirSistema: function () {
      this.$router.replace("/");
    },
  }
}
</script>

<style>

</style>