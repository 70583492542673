<template>
  <v-card
    :class="
      (carrera.carrera_estado_id < 1000
        ? 'bg-light-blue-lighten-4'
        : 'bg-teal-lighten-4')+
        ' rounded-xl'
    "
  >
    <v-card-title >
      <v-row dense no-gutters :style="'font-size:'+sizeText.card.lista.destacado.size+'px;font-weight: bold;'">
        <v-col cols="4">{{ carrera.comprobante }}</v-col>
        <v-col cols="4" align="center">{{
          carrera.kilometros + " km" + (carrera.kilometros > 1 ? "s" : "") + "."
        }}</v-col>
        <v-col cols="4" align="right">
            <span v-if="carrera.duracion_horas>0">{{ carrera.duracion_horas+"Hr"+(carrera.duracion_horas>1?"s":"") }}</span>
            {{ carrera.duracion_minutos+"Min"+(carrera.duracion_minutos>1?"s":"") }}
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-subtitle>
      <v-row dense no-gutters>
        <v-col cols="6" align="center">Estado: {{ carrera.estado_descripcion }}</v-col>
        <v-col cols="6" align="center">{{
          formatoFechaHoraTXT(carrera.fechahora, false)
        }}</v-col>
      </v-row>
    </v-card-subtitle>
    <v-card-text dense class="text-caption">
      <v-row dense no-gutters>
        <v-col cols="6" :style="'font-size:'+sizeText.card.lista.destacado.size+'px;font-weight: bold;'" align="center">
          {{ formatoMonedaChile(carrera.valor_total) }}
        </v-col>
        <v-col cols="6" align="center"
          >{{ carrera.servicio_nombre }}<br />
          {{ carrera.destinos }} destino{{
            parseInt(carrera.destinos) > 1 ? "s" : ""
          }}</v-col
        >
      </v-row>
    </v-card-text>
    <v-card-action>
      <v-spacer />
      <v-row class="pa-2" dense>
        <v-col align="center">
          <v-btn
            density="compact"
            icon="mdi-pencil-outline"
            v-if="parseInt(carrera.carrera_estado_id) < 1000"
            @click="modificarCarrera(carrera)"
          ></v-btn>
        </v-col>
        <v-col align="center">
          <v-btn
            density="compact"
            icon="mdi-map-marker-check-outline"
            v-if="parseInt(carrera.carrera_estado_id) != 1000"
            @click="cerrarCarrera(carrera)"
          ></v-btn>
        </v-col>
        <v-col align="center">
          <v-btn
            density="compact"
            icon="mdi-map-marker-right-outline"
            @click="vermapa(carrera)"
          ></v-btn>
        </v-col>
      </v-row>
    </v-card-action>
  </v-card>
  <ver_mapa_carrera ref="ver_mapa_carrera"/>
</template>

<script>
import {
  formatoFechaHoraTXT,
  formatoMonedaChile,
  isMobile,
  sizeText,
} from "@/helpers/helpers";
import ver_mapa_carrera from "@/components/carrera/ver_mapa_carrera.vue";
export default {
  data: () => ({
    formatoFechaHoraTXT: formatoFechaHoraTXT,
    formatoMonedaChile: formatoMonedaChile,
    sizeText:sizeText,
  }),
  props: {
    carrera: Object,
    index: Number,
  },
  components: {
    ver_mapa_carrera,
  },
  methods: {
    modificarCarrera: function(carrera){
        this.$emit("modificarCarrera",carrera);
    },
    cerrarCarrera: function(carrera){
        this.$emit("cerrarCarrera",carrera);
    },
    
    vermapa: function(carrera){
      this.$refs.ver_mapa_carrera.processRouteData2(
        carrera.polyline,
        carrera.legs,
        carrera.kilometros
      );
    },
  }
};
</script>

<style>
</style>