<template flat>
    <v-btn
      :style="'background-color:'+paletColor.buttons.submit.background+';color: '+paletColor.buttons.submit.text+' ;font-size: 12px;'"
      dark
      block
      title
      type="submit"
      :loading="validating"
    :disabled="readonly"
      >{{ label }}</v-btn
    >
</template>

<script>

import { paletColor } from "@/helpers/helpers";
export default {
  data: () => ({
    label: 'Enviar',
    paletColor: paletColor,
    readonly: false,
  }),
  props: {
    validating: Boolean,
    labelSet: String,
    readonlySet: Boolean,
  },
  watch: {
    labelSet: function(v){
      this.label = v;
    },
    readonlySet: function(v){
      this.readonly = v;
    },
  },
  mounted: function(){
    if(this.labelSet){
      this.label = this.labelSet;
    }
    if(this.readonlySet){
      this.readonly = this.readonlySet;
    }
  }
};
</script>

<style>
</style>