<template>
  <v-dialog
    persistent
    width="90%"
    max-width="90%"
    v-if="localdialogverutamapa"
    v-model="localdialogverutamapa"
  >
    <v-card
      elevation-6
      :style="
        'background-color: ' +
        paletColor.card.backgroundgral +
        ';color: ' +
        paletColor.colorNeutro +
        ';'
      "
      width="90%"
    >
      <v-card-title> Ruta </v-card-title>
      <v-card-text
        :style="'background-color: ' + paletColor.colorFondoAlternativo + ';'"
      >
        <GMapMap
          :center="center"
          :zoom="zoom"
          style="width: 100%; height: 370px"
          ref="gmap"
        >
          <!-- Dibujar la ruta si está disponible -->
          <GMapPolyline :path="routePath" :options="polylineOptions" />
          <GMapMarker
            v-for="(marker, index) in markers"
            :key="index"
            :position="marker.position"
            :options="markerOptions"
            :icon="marker.icon"
            @click="openInfoWindow(marker)"
          >
          </GMapMarker>
          <GMapInfoWindow
            ref="infoWindow"
            v-if="isInfoWindowOpen"
            :opened="isInfoWindowOpen"
            :position="selectedMarker.position"
            :options="{ maxWidth: 200 }"
            @click="onInfoWindowClose"
            @suspend="onInfoWindowClose"
          >
            <template #default>
              <div :style="'color: ' + paletColor.colorTexto + ';'">
                <h4>{{ selectedMarker.title }}</h4>
                <p>{{ selectedMarker.description }}</p>
              </div>
            </template>
          </GMapInfoWindow>
        </GMapMap>
      </v-card-text>
      <v-card-action>
        <v-container>
          <v-row dense>
            <v-col cols="12" :md="anchoBoton"
              ><buttonCancelform labelSet="Cerrar" @cerrar="cerrar"
            /></v-col>
            <v-col cols="12" :md="anchoBoton"
              ><buttonCancelform
                labelSet="Iniciar Ruta Google"
                @cerrar="navigateToGoogleMaps"
                preIcon="mdi-google-maps"
            /></v-col>

            <v-col cols="12" :md="anchoBoton" v-if="isMobile()"
              ><buttonCancelform
                labelSet="Iniciar Ruta Waze"
                @cerrar="navigateToWaze"
                preIcon="mdi-waze"
            /></v-col>
          </v-row>
        </v-container>
      </v-card-action>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  enviarJsonPOST,
  formatoMonedaChile,
  paletColor,
  isMobile,
} from "@/helpers/helpers.js";
import { createLogger } from "vuex";
import buttonCancelform from "../form/buttonCancelform.vue";

export default {
  data: () => ({
    zoom: 8,
    polylineOptions: {
      strokeColor: "#325A82", // Color de la línea (rojo)
      strokeOpacity: 1.0, // Opacidad de la línea
      strokeWeight: 3, // Grosor de la línea
    },
    localdialogverutamapa: false,
    isInfoWindowOpen: false,
    selectedMarker: null,
    paletColor: paletColor,
    center: { lat: -33.45, lng: -70.65 }, // Centro inicial del mapa
    routePath: [], // Contendrá los puntos de la ruta a dibujar
    markers: [],
    origenlocal: null,
    destinolocal: null,
    legs_local: null,
    overview_polyline_points: null,
    anchoBoton: 6,
    isMobile: isMobile,
    distancia: 40,
    peajes: [],
  }),
  props: {
  },
  components: {
    buttonCancelform,
  },
  watch: {},

  mounted: async function () {
    const usuario = JSON.parse(sessionStorage.getItem("usuarioDriverIn"));
    if (this.isMobile()) {
      this.anchoBoton = 4;
    } else {
      this.anchoBoton = 6;
    }
  },
  methods: {
    cerrar: function () {
      this.localdialogverutamapa = false;
    },
    onInfoWindowClose: function () {
      this.isInfoWindowOpen = false;
    },
    openInfoWindow: function (marker) {
      this.isInfoWindowOpen = true;
      this.selectedMarker = marker;
    },
    mostrarMapa: function () {
      this.localdialogverutamapa = true;
    },
    processRouteData2: function () {
      this.isInfoWindowOpen = null;
      this.selectedMarker = null;
      this.routePath = null;
      this.markers = [];
      let overviewPolyline = null;
      if (arguments.length > 0) {
        overviewPolyline = arguments[0];
      }
      if (arguments.length > 1) {
        this.legs_local = arguments[1];
      }
      if (arguments.length > 2) {
        this.distancia = arguments[2];
      }
      if (arguments.length > 3) {
        this.peajes = arguments[3];
      }
      if (overviewPolyline) {
        const steps1 = this.decodePolyline(overviewPolyline);
        this.overview_polyline_points = steps1;

        const steps = new Proxy(steps1, {
          get(target, prop) {
            // Interceptar el acceso a propiedades del array
            if (prop in target) {
              //console.log(`Accediendo al índice ${prop}: ${target[prop]}`);
              return target[prop];
            }
            return undefined; // Devuelve undefined si la propiedad no existe
          },
          set(target, prop, value) {
            // Interceptar la asignación de valores en el array
            //console.log(`Estableciendo el índice ${prop} a ${value}`);
            target[prop] = value; // Asignar el valor al índice
            return true; // Indicar que la operación fue exitosa
          },
          deleteProperty(target, prop) {
            // Interceptar la eliminación de elementos en el array
            //console.log(`Eliminando el índice ${prop}`);
            delete target[prop]; // Eliminar el índice
            return true; // Indicar que la operación fue exitosa
          },
        });

        const path = [];
        steps.forEach((step) => {
          // Cada paso tiene un inicio y fin de latitud y longitud
          const startLatLng = {
            lat: step.lat,
            lng: step.lng,
          };

          // Añadir ambos puntos al path
          path.push(startLatLng);
          //path.push(endLatLng);
        });
        this.routePath = path; // Actualizamos el camino a dibujar

        //calculo de peajes
        this.peajes.forEach((item,index) => {
          
          //console.log("item,index",item,index);
        });
        let centro =
          Math.floor(this.routePath.length / 2) - 1 < 0
            ? 0
            : Math.floor(this.routePath.length / 2) - 1;
        
            this.center = {
              lat: this.routePath[centro].lat,
              lng: this.routePath[centro].lng,
            };

        if (typeof this.legs_local == "object") {
          for (let x = 0; x < this.legs_local.length; x++) {
            if (x == 0) {
              this.markers.push({
                position: this.legs_local[x].origen,
                title: "Inicio",
                icon: "img/iconos/IconoMapaLocationsInicio.gif",
                description: this.legs_local[x].origen.direccion,
              });
            }
            this.markers.push({
              position: this.legs_local[x].destino,
              title: "Termino",
              icon: "img/iconos/IconoMapaLocationsFinal.gif",
              description: this.legs_local[x].destino.direccion,
            });
          }
        }
      }
      this.setZoomLevelByDistance();
      this.localdialogverutamapa = true;
    },
    navigateToGoogleMaps() {
      const points = this.overview_polyline_points;

      if (points.length > 0) {
        const origin = points[0]; // Primer punto de la ruta
        const destination = points[points.length - 1]; // Último punto de la ruta

        let waypoints = "";
        if(this.markers){
          for(let m=0;m<this.markers.length;m++){
            if(m!=0 && m<this.markers.length-1){
              if(waypoints == ""){
                waypoints = `${this.markers[m].position.lat},${this.markers[m].position.lng}`;
              }else{
                waypoints += "|"+`${this.markers[m].position.lat},${this.markers[m].position.lng}`;
              }
            }
          }
        }


        // Construir URL de Google Maps con origin, destination y waypoints
        const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}&waypoints=${waypoints}&travelmode=driving`;

        // Redirigir a Google Maps
        window.open(googleMapsUrl, "_blank");
      }
    },

    navigateToWaze() {
      if (
        confirm(
          "Al abrir Waze, puede que entregue una ruta distinta a lo calculado, ¿esta conforme con eso?"
        )
      ) {
        const points = this.overview_polyline_points;

        if (points.length > 0) {
          const destination = points[points.length - 1]; // Último punto de la ruta

          // Construir URL de Waze con la latitud y longitud del destino
          const wazeUrl = `waze://?ll=${destination.lat},${destination.lng}&navigate=yes`;

          // Redirigir a Waze
          window.open(wazeUrl, "_blank");
        }
      }
    },
    decodePolyline: function (encoded) {
      let points = [];
      let index = 0,
        len = encoded.length;
      let lat = 0,
        lng = 0;
      let c = 0;
      while (index < len) {
        c++;
        let b,
          shift = 0,
          result = 0;
        do {
          b = encoded.charCodeAt(index++) - 63;
          result |= (b & 0x1f) << shift;
          shift += 5;
        } while (b >= 0x20);
        let dlat = result & 1 ? ~(result >> 1) : result >> 1;
        lat += dlat;

        shift = 0;
        result = 0;
        do {
          b = encoded.charCodeAt(index++) - 63;
          result |= (b & 0x1f) << shift;
          shift += 5;
        } while (b >= 0x20);
        let dlng = result & 1 ? ~(result >> 1) : result >> 1;
        lng += dlng;

        if (lat != null && lng != null && c / 1 == Math.trunc(c / 1)) {
          points.push({ lat: lat / 1e5, lng: lng / 1e5 });
        }
      }

      return points;
    },
    setZoomLevelByDistance() {
      // Ajustar el nivel de zoom en función de la distancia
      let distance = Math.floor(this.distancia);
      if (distance < 1) {
        this.zoom = 15; // Muy cerca
      } else if (distance < 5) {
        this.zoom = 14;
      } else if (distance < 10) {
        this.zoom = 13;
      } else if (distance < 20) {
        this.zoom = 11;
      } else if (distance < 50) {
        this.zoom = 10;
      } else if (distance < 100) {
        this.zoom = 9;
      } else if (distance < 200) {
        this.zoom = 8;
      } else {
        this.zoom = 6; // Muy lejos
      }
    },
  },
};
</script>

<style>
</style>
