<template>
  <v-dialog
      persistent
      width="auto"
      max-width="70%"
      v-if="dialogEvalMessage"
      v-model="dialogEvalMessage"
    >
      <v-card class="mx-auto elevation-6 mt-10"
      :style="'background-color: ' + paletColor.card.backgroundgral + ';'">
      <v-card-title
        :style="'color: ' + paletColor.buttons.cancel.text + ';'"
      >
      {{title}} {{ code?" - "+code:"" }} {{ number ? " : " + " - "+number : "" }}
      </v-card-title>
        <v-card-text :style="'background-color: ' + paletColor.colorNeutro + ';'">
          {{ message }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col cols="12" align="center">
                <buttonCancelform labelSet="Aceptar" preIcon="mdi-check" @accion="cerrar"/>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import buttonCancelform from '@/components/form/buttonCancelform.vue';
import {paletColor} from "@/helpers/helpers";
export default {
    data: () => ({
        dialogEvalMessage: false,
        title:  null,
        titleBase: "Información",
        code: null,
        number: null,
        message: null,
        paletColor: paletColor,
    }),
    components: {
        buttonCancelform,
    },
    methods: {
        /**
         * Permite mostrar mensajes en el sistema
         * @param mensaje Corresponde al mensaje que se mostrará
         * @param titulo Corresponde al número de error que se muestra en el sistema
         * @param numero Corresponde al número de error que se muestra en el sistema
         * @param code Corresponde al codigo de error que se muestra en el sistema
         */
        mostrar: function(){
            if(arguments.length > 0){
                this.message = arguments[0];
            }
            if(arguments.length > 1){
                this.title = arguments[1];
            }else{
                this.title = this.titleBase;
            }
            if(arguments.length > 2){
                this.number = arguments[2];
            }
            if(arguments.code > 3){
                this.code = arguments[3];
            }
            if(arguments.length > 0){
                this.dialogEvalMessage = true;
            }
        },
        cerrar: function(){
            this.message = null;
            this.title = this.titleBase;
            this.number = null;
            this.code = null;
            this.dialogEvalMessage = false;
            this.$emit("cerrar");
        }
    }
}
</script>

<style>

</style>