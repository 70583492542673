<template>
  
  <v-container  :class="isMobile?'px-1 py-1':''">
    
    <v-row>
        <v-col class="pa-4" align="center">
        
        <v-btn large icon="mdi-water-plus" size="x-large" @click="NuevoCombustible"
        :style="'background-color: ' + paletColor.card.backgroundgral + ';color: '+paletColor.buttons.cancel.text+';'">
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  obtenerJsonGET,
  formatoFechaHoraTXT,
  formatoMonedaChile,
  paletColor,
  isMobile,
} from "@/helpers/helpers";
export default {
  data: () => ({
    isMobile: isMobile(),
    paletColor:paletColor,
  }),
}
</script>

<style>

</style>